<template>
  <div class="game-html-wrapper">
    <!-- eslint-disable vue/no-v-html -->
    <section
      v-if="!loading"
      v-html="gameDetail"
    />
    <section v-else>
      <!-- eslint-disable vue/max-attributes-per-line -->
      <van-skeleton title :row="3" :loading="loading" />
      <van-skeleton title :row="3" :loading="loading" />
      <van-skeleton title :row="3" :loading="loading" />
    </section>
  </div>
</template>

<script>
import { getSingleGame } from '@/api/game'
import Vue from 'vue'
import { Skeleton } from 'vant'
import { mapMutations } from 'vuex'
Vue.use(Skeleton)

export default {
  data () {
    return {
      gameDetail: '',
      loading: true
    }
  },
  created () {
    if (!this.$route.query.gameId) return false
    const locale = this.$route.query.locale || 'en'
    this.SET_LANGUAGE(locale)
    const token = this.$route.query.token
    this.SET_TOKEN(token)
    getSingleGame({
      gameId: this.$route.query.gameId
    })
      .then(res => {
        const docs = res.data.docs || {}
        this.gameDetail = (docs[locale] || docs[Object.keys(docs)[0]]).gameDetail
        this.loading = false
      })
  },
  methods: {
    ...mapMutations('service', ['SET_LANGUAGE', 'SET_TOKEN'])
  }
}
</script>

<style lang="less">
  .game-html-wrapper {
    box-sizing: border-box;
    padding: 20px;
    img {
      max-width:100% ;
    }
    .van-skeleton {
      margin-bottom: 20px;
    }
  }
</style>
